<template>
  <div
    v-if="
      $store.state.fastPermissoes.administrador == 'S' &&
      $store.state.fastPermissoes.ativo == 'S'
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color"
                >Minhas Plataformas</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                >Modo admin</a
              >
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#">Tutoriais.</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  <span>Tutoriais</span>
                </h1>
                <img
                  v-lazy="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                />
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <!-- Conteúdo principal -->
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <!-- começa grid -->

              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-10">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de Tutoriais
                      </h6>
                    </div>
                    <div class="col-2">
                      <button
                        class="btn btn-sm btn-primary"
                        @click="exibeModalCadastroTutoriais()"
                      >
                        + Adicionar Conta
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th>
                              <small class="font-weight-bold">Descrição</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Conteúdo</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>

                        <tbody v-if="fastTutorialTotal.length">
                          <tr
                            v-for="Tutorial in fastTutorialTotal"
                            :key="Tutorial.id_tutorial"
                          >
                            <td class="align-middle text-center">
                              {{ Tutorial.descricao }}
                            </td>

                            <td class="text-center">
                              <div v-if="Tutorial.tipo == 'A'">
                                <a
                                  class="btn btn-primary btn-sm mt-2 ml-2"
                                  :href="ajustaLinkApiAntiga(Tutorial.url)"
                                  target="_blank"
                                  download
                                >
                                  <small>
                                    <i class="fa fa-eye" aria-hidden="true" />
                                    &nbsp;Visualizar
                                  </small>
                                </a>
                              </div>
                              <div v-else-if="Tutorial.tipo == 'V'">
                                <button
                                  class="btn btn-primary btn-sm mt-2 ml-2"
                                  @click="visualizarAtividade(Tutorial)"
                                >
                                  <small>
                                    <i class="fa fa-play" aria-hidden="true" />
                                    &nbsp;Visualizar
                                  </small>
                                </button>
                              </div>
                            </td>
                            <td class="text-center align-middle">
                              <button
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="exibeModalEditarTutorial(Tutorial)"
                              >
                                <small>Editar</small>
                              </button>
                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                @click="exibeModalExcluirTutorial(Tutorial)"
                              >
                                <small>Excluir</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="9" class="text-center">
                              Nenhum Tutorial encontrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="10"
                  :items="fastOcorrenciaFiltro"
                  @changePage="fastOcorrenciaPagination = $event"
                />
              </div> -->
                  </div>
                </div>
              </div>

              <!--termina grid -->
            </div>
          </div>
        </div>
        <!-- /Conteúdo principal -->
        <!-- Voltar -->
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
            >
              <b-icon-chevron-double-left></b-icon-chevron-double-left>Voltar
            </a>
          </div>
        </div>
        <!-- /Voltar -->
        <!-- Modais -->
        <modal
          name="exibeModalCadastroTutoriaisYoutube"
          :shift-y="0.1"
          height="auto"
          :width="modalWidthMd"
          :scrollable="true"
          :adaptative="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Cadastro do Tutorial</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('exibeModalCadastroTutoriaisYoutube')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                              <label class="h6 mt-4 aluno_font_color"
                                >Descrição do Tutorial</label
                              >
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Descrição do Tutorial"
                                v-model="fastTutorialNovo.descricao"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <label class="h6 mt-4 aluno_font_color"
                              >Conteúdo do Tutorial</label
                            >
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <div class="text-center">
                                <div class="col-12 pt-4 pr-4 pb-0 pl-4">
                                  <div class="row">
                                    <div class="col-12">
                                      <h6>Insira a URL do video do Youtube:</h6>
                                    </div>
                                    <div class="col-12">
                                      <input
                                        v-model="nova_referencia_atividade"
                                        class="form-control"
                                        type="text"
                                        placeholder="Ex: https://www.youtube.com/watch?v=xSNaIWXJm0Y"
                                        @keyup="validaVideoYoutube()"
                                      />
                                    </div>
                                    <div
                                      v-if="nova_referencia_atividade"
                                      class="col-12 mt-4 text-center"
                                    >
                                      <h6>Preview:</h6>
                                      <iframe
                                        :src="ajustaLinkApiAntiga(nova_referencia_atividade)"
                                        frameborder="0"
                                        height="200px"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-4">
                            <div class="form-group mb-0">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click="salvarTutorialYoutube()"
                              >
                                Salvar Tutorial
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="exibeModalCadastroTutoriaisArquivo"
          :shift-y="0.1"
          height="auto"
          :width="modalWidthMd"
          :scrollable="true"
          :adaptative="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Cadastro do Tutorial</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('exibeModalCadastroTutoriaisArquivo')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                              <label class="h6 mt-4 aluno_font_color"
                                >Descrição do Tutorial</label
                              >
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Descrição do Tutorial"
                                v-model="fastTutorialNovo.descricao"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <label class="h6 mt-4 aluno_font_color"
                              >Conteúdo do Tutorial</label
                            >
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <div class="form-group">
                                <uploader
                                  :file-status-text="statusText"
                                  :options="optionsArquivo"
                                  class="uploader-example"
                                  @file-success="fileSuccess"
                                  @file-added="fileValidation"
                                >
                                  <uploader-unsupport />
                                  <uploader-drop>
                                    <p class="aluno_font_color">
                                      Arraste um arquivo PDF/HTML aqui ou
                                    </p>
                                    <uploader-btn :attrs="restrictArquivo">
                                      Clique aqui para selecionar
                                    </uploader-btn>
                                  </uploader-drop>
                                  <uploader-list />
                                </uploader>
                              </div>
                              <div
                                v-if="fastTutorialNovo.url"
                                class="form-group"
                              >
                                <div
                                  v-if="fastTutorialNovo.url"
                                  class="text-center"
                                >
                                  <iframe
                                    v-if="
                                      fastTutorialNovo.url.split('.').pop() ==
                                        'ppt' ||
                                      fastTutorialNovo.url.split('.').pop() ==
                                        'pptx'
                                    "
                                    :src="
                                      'https://docs.google.com/gview?url=' +
                                      ajustaLinkApiAntiga(fastTutorialNovo.url) +
                                      '&embedded=true'
                                    "
                                    frameborder="0"
                                    allowfullscreen
                                    class="w-100"
                                    style="min-height: 500px"
                                  />
                                  <object
                                    v-else-if="
                                      fastTutorialNovo.url.split('.').pop() ==
                                      'pdf'
                                    "
                                    :data="fastTutorialNovo.url"
                                    type="application/pdf"
                                    class="w-100"
                                    style="min-height: 500px"
                                  >
                                    <embed
                                      :src="ajustaLinkApiAntiga(fastTutorialNovo.url)"
                                      type="application/pdf"
                                    />
                                  </object>
                                  <iframe
                                    v-else-if="
                                      fastTutorialNovo.url.split('.').pop() ==
                                      'html'
                                    "
                                    :src="ajustaLinkApiAntiga(fastTutorialNovo.url)"
                                    frameborder="0"
                                    allowfullscreen
                                    class="w-100"
                                    style="min-height: 500px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-4">
                            <div class="form-group mb-0">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click="salvarTutorialArquivo()"
                              >
                                Salvar Tutorial
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="modalExcluirTutorial"
          :scrollable="true"
          height="auto"
          :shift-y="0.1"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-6 pl-4 pr-4">
              <h4>Excluir Tutorial?</h4>
            </div>
            <div class="col-6 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalExcluirTutorial')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <button
                      href="#"
                      type="button"
                      class="btn btn-danger"
                      @click.prevent="excluirTutorial()"
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="exibeModalCadastroTutorial"
          :shift-y="0.1"
          height="auto"
          :width="modalWidthMd"
          :scrollable="true"
          :adaptative="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Escolha uma Opção</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('exibeModalCadastroTutorial')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-6">
                            <div
                              class="card"
                              @click.prevent="
                                hideModal('exibeModalCadastroTutorial');
                                showModal('exibeModalCadastroTutoriaisArquivo');
                              "
                            >
                              <div class="card-body text-center">
                                <h5 class="card-title">Pdf ou Html</h5>
                                <b-icon-file-earmark-text font-scale="3" />
                                <p class="card-text">
                                  Clique aqui para criar um tutorial com Pdf ou
                                  Html.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div
                              class="card"
                              @click.prevent="
                                hideModal('exibeModalCadastroTutorial');
                                showModal('exibeModalCadastroTutoriaisYoutube');
                              "
                            >
                              <div class="card-body text-center">
                                <h5 class="card-title">Vídeo do Youtube</h5>
                                <b-icon-collection-play-fill font-scale="3" />
                                <p class="card-text">
                                  Clique aqui para criar um tutorial com vídeo
                                  do youtube.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="modalVisualizarAtividade"
          :scrollable="true"
          height="auto"
          :shift-y="0.1"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-6 pl-4 pr-4">
              <h4>Tutorial - {{ fastTutorialVisualiza.descricao }}</h4>
            </div>
            <div class="col-6 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVisualizarAtividade')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div v-if="fastTutorialVisualiza.tipo == 'V'" class="col-12">
                  <iframe
                    :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)"
                    width="100%"
                    height="315"
                    frameborder="0"
                  />
                </div>
                <div v-else class="col-12">
                  <audio controls class="d-block m-auto">
                    <source :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)" type="audio/mpeg" />
                    Seu navegador não suporte o player de audio.
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="exibeModalEditarTutoriaisArquivo"
          :shift-y="0.1"
          height="auto"
          :width="modalWidthMd"
          :scrollable="true"
          :adaptative="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Editar Tutorial</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('exibeModalEditarTutoriaisArquivo')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                              <label class="h6 mt-4 aluno_font_color"
                                >Descrição do Tutorial</label
                              >
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Descrição do Tutorial"
                                v-model="fastTutorialEdita.descricao"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <label class="h6 mt-4 aluno_font_color"
                              >Conteúdo do Tutorial</label
                            >
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <div class="form-group">
                                <uploader
                                  :file-status-text="statusText"
                                  :options="optionsArquivo"
                                  class="uploader-example"
                                  @file-success="fileSuccessEdita"
                                  @file-added="fileValidation"
                                >
                                  <uploader-unsupport />
                                  <uploader-drop>
                                    <p class="aluno_font_color">
                                      Arraste um arquivo PDF/HTML aqui ou
                                    </p>
                                    <uploader-btn :attrs="restrictArquivo">
                                      Clique aqui para selecionar
                                    </uploader-btn>
                                  </uploader-drop>
                                  <uploader-list />
                                </uploader>
                              </div>
                              <div
                                v-if="fastTutorialEdita.url"
                                class="form-group"
                              >
                                <div
                                  v-if="fastTutorialEdita.url"
                                  class="text-center"
                                >
                                  <iframe
                                    v-if="
                                      fastTutorialEdita.url.split('.').pop() ==
                                        'ppt' ||
                                      fastTutorialEdita.url.split('.').pop() ==
                                        'pptx'
                                    "
                                    :src="
                                      'https://docs.google.com/gview?url=' +
                                      ajustaLinkApiAntiga(fastTutorialEdita.url) +
                                      '&embedded=true'
                                    "
                                    frameborder="0"
                                    allowfullscreen
                                    class="w-100"
                                    style="min-height: 500px"
                                  />
                                  <object
                                    v-else-if="
                                      fastTutorialEdita.url.split('.').pop() ==
                                      'pdf'
                                    "
                                    :data="fastTutorialEdita.url"
                                    type="application/pdf"
                                    class="w-100"
                                    style="min-height: 500px"
                                  >
                                    <embed
                                      :src="ajustaLinkApiAntiga(fastTutorialEdita.url)"
                                      type="application/pdf"
                                    />
                                  </object>
                                  <iframe
                                    v-else-if="
                                      fastTutorialEdita.url.split('.').pop() ==
                                      'html'
                                    "
                                    :src="ajustaLinkApiAntiga(fastTutorialEdita.url)"
                                    frameborder="0"
                                    allowfullscreen
                                    class="w-100"
                                    style="min-height: 500px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-4">
                            <div class="form-group mb-0">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click="editarTutorial()"
                              >
                                Salvar Tutorial
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="exibeModalEditaTutoriaisYoutube"
          :shift-y="0.1"
          height="auto"
          :width="modalWidthMd"
          :scrollable="true"
          :adaptative="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Editar Tutorial</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('exibeModalEditaTutoriaisYoutube')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                              <label class="h6 mt-4 aluno_font_color"
                                >Descrição do Tutorial</label
                              >
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Descrição do Tutorial"
                                v-model="fastTutorialEdita.descricao"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <label class="h6 mt-4 aluno_font_color"
                              >Conteúdo do Tutorial</label
                            >
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <div class="text-center">
                                <div class="col-12 pt-4 pr-4 pb-0 pl-4">
                                  <div class="row">
                                    <div class="col-12">
                                      <h6>Insira a URL do video do Youtube:</h6>
                                    </div>
                                    <div class="col-12">
                                      <input
                                        v-model="fastTutorialEdita.url"
                                        class="form-control"
                                        type="text"
                                        placeholder="Ex: https://www.youtube.com/watch?v=xSNaIWXJm0Y"
                                        @keyup="validaVideoYoutube()"
                                      />
                                    </div>
                                    <div
                                      v-if="fastTutorialEdita.url"
                                      class="col-12 mt-4 text-center"
                                    >
                                      <h6>Preview:</h6>
                                      <iframe
                                        :src="ajustaLinkApiAntiga(fastTutorialEdita.url)"
                                        frameborder="0"
                                        height="200px"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-4">
                            <div class="form-group mb-0">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click="editarTutorial()"
                              >
                                Salvar Tutorial
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>
        <!-- /Modais -->
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoAdminTutoriais",
  // Componentes
  components: {},
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nova_referencia_atividade: "",
      fastTutorialTotal: [],
      fastTutorialEdita: {
        id_tutorial: "",
        descricao: "",
        url: "",
        tipo: "",
        id_plataforma: this.$route.params.id_plataforma,
      },
      fastTutorialLoading: true,

      // Upload arquivo
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_plataforma_tutorial/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          tipo: "A",
          id_plataforma: this.$route.params.id_plataforma,
          id_tutorial: this.$route.params.id_tutorial,
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
            "rar",
          ],
        },

        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },

      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },

      fastTutorialNovo: {
        descricao: "",
        url: "",
        id_plataforma: this.$route.params.id_plataforma,
        tipo: "",
      },

      
      fastTutorialVisualiza: {},
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, "administrador")
        .then(() => {
          this.$store.state.fastCarregando = false;
          this.getTutorial(this.$route.params.id_plataforma);
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
    //busca tutoriais
    async getTutorial(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_plataforma_tutorial/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((obj) => {
          if (obj.length) {
            this.fastTutorialTotal = obj;
          } else {
            this.fastTutorialTotal = [];
          }
          this.$store.state.fastCarregando = false;
          this.fastTutorialLoading = false;
        })
        .catch((e) => {
          this.exibeToasty("Erro ao buscar Tutorial", "error");
          this.$store.state.fastCarregando = false;
          this.fastTutorialficaLoading = false;
        });
    },
    //Cadastro de Tutoriais
    exibeModalCadastroTutoriais() {
      this.showModal("exibeModalCadastroTutorial");
    },

    async salvarTutorialArquivo() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        descricao: this.fastTutorialNovo.descricao,
        url: this.fastTutorialNovo.url,
        tipo: "A",
      };
      this.$store.state.fastCarregando = true;
      this.promiseInserirFastApi(obj, "fast_plataforma_tutorial")
        .then((res) => {
          this.$store.state.fastCarregando = false;
          if (res.length) {
            this.exibeToasty("Tutorial salvo com sucesso", "success");
            this.getTutorial(this.$route.params.id_plataforma);
            this.hideModal("exibeModalCadastroTutoriaisArquivo");
            this.fastTutorialNovo = {
              descricao: "",
              url: "",
              tipo: "",
            };
          } else {
            this.exibeToasty("Erro ao salvar documento", "error");
          }
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao salvar documento", "error");
        });
    },

    async salvarTutorialYoutube() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        descricao: this.fastTutorialNovo.descricao,
        url: this.nova_referencia_atividade,
        tipo: "V",
      };
      this.$store.state.fastCarregando = true;
      this.promiseInserirFastApi(obj, "fast_plataforma_tutorial")
        .then((res) => {
          this.$store.state.fastCarregando = false;
          if (res.length) {
            this.exibeToasty("Tutorial salvo com sucesso", "success");
            this.getTutorial(this.$route.params.id_plataforma);
            this.hideModal("exibeModalCadastroTutoriaisYoutube");
            this.fastTutorialNovo = {
              descricao: "",
              url: "",
              tipo: "",
            };
          } else {
            this.exibeToasty("Erro ao salvar documento", "error");
          }
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao salvar documento", "error");
        });
    },

    validaVideoYoutube() {
      if (this.nova_referencia_atividade.indexOf("watch?v=") != "-1") {
        let link = this.nova_referencia_atividade.split("watch?v=");
        this.nova_referencia_atividade =
          "https://www.youtube.com/embed/" + link.pop();
      } else if (this.fastTutorialEdita.url.indexOf("watch?v=") != "-1") {
        let link = this.fastTutorialEdita.url.split("watch?v=");
        this.fastTutorialEdita.url =
          "https://www.youtube.com/embed/" + link.pop();
      }
    },
    fileValidation(e) {
      return false;
    },

    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastTutorialNovo.url = false;
        setTimeout(() => {
          this.fastTutorialNovo.url = result.url;
        }, 1000);
      } else if (!result.error) {
        this.fastTutorialEdita.url = false;
        setTimeout(() => {
          this.fastTutorialEdita.url = result.url;
        }, 1000);
      }
    },

    fileSuccessEdita(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastTutorialEdita.url = false;
        setTimeout(() => {
          this.fastTutorialEdita.url = result.url;
        }, 1000);
      }
    },

    //excluir Tutoriais
    exibeModalExcluirTutorial(Tutorial) {
      this.fastTutorialVisualiza = Tutorial;
      this.showModal("modalExcluirTutorial");
    },
    async excluirTutorial() {
      this.promiseExcluirFastApi(
        this.fastTutorialVisualiza,
        "fast_plataforma_tutorial"
      )
        .then((e) => {
          this.exibeToasty("Tutorial excluído com sucesso", "success");
          this.hideModal("modalExcluirTutorial");
          this.getTutorial(this.$route.params.id_plataforma);
        })
        .catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
        });
    },
    //editar Tutoriais
    exibeModalEditarTutorial(Tutorial) {
      this.fastTutorialEdita = Tutorial;
      if (this.fastTutorialEdita.tipo == "V") {
        this.showModal("exibeModalEditaTutoriaisYoutube");
      } else {
        this.showModal("exibeModalEditarTutoriaisArquivo");
      }
    },
    async editarTutorial() {
      let erros = [];

      if (!this.fastTutorialEdita.descricao)
        erros.push("Descrição do Tutorial é obrigatório");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(
          this.fastTutorialEdita,
          "fast_plataforma_tutorial"
        )
          .then((e) => {
            this.exibeToasty("Salvo com sucesso", "success");
            this.getTutorial(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
            this.hideModal("exibeModalEditaTutoriaisYoutube");
            this.hideModal("exibeModalEditarTutoriaisArquivo");
            this.fastTutorialEdita = {
              descricao: "",
              url: "",
              tipo: "",
            };
          })
          .catch((e) => {
            this.exibeToasty("Erro ao salvar alterações", "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    //Visualizador atividade
    visualizarAtividade(Tutorial) {
      if (Tutorial.tipo == "V") {
        if (Tutorial.url.indexOf("https://vimeo.com/") != "-1") {
          Tutorial.url =
            "https://player.vimeo.com/video/" + Tutorial.url.split("/").pop();
        }
      }
      this.fastTutorialVisualiza = Tutorial;
      this.showModal("modalVisualizarAtividade");
    },
  },
};
</script>

<style></style>
